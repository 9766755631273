// 1. Your custom variables and variable overwrites.
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@200;400;600;800&display=block');
$global-link-color: #BF9B5C;
$global-link-hover-color: #d8a958;
$global-font-family: 'Spartan';
$base-heading-font-weight: 600;
$global-primary-background: #BF9B5C;
$global-secondary-background: #262626;
$button-secondary-background: #464646;
$button-secondary-hover-background: #262626;
$global-emphasis-color: #262626;

$base-heading-font-weight: 100;


// 2. Import default variables and available mixins.
@import '../../../node_modules/uikit/src/scss/variables-theme.scss';
@import '../../../node_modules/uikit/src/scss/mixins-theme.scss';

// 3. Your custom mixin overwrites.
// @mixin hook-card() { color: #000; }

// 4. Import UIkit.
@import '../../../node_modules/uikit/src/scss/uikit-theme.scss';




html {
  visibility: visible;
  opacity: 1;
}

  .uk-button {
    margin-top: -3px;
    line-height: 38px;
    padding: 3px 30px 0 30px;
  }



.offcanvas-icons {
  text-align: center;
  margin: 0 auto;
  a { margin: 0px 5px; }
}

.uk-offcanvas-bar {
  background: #262626;
  width: 100vw;
}

.uk-offcanvas-bar .uk-button-primary {
  background: $global-link-color;
  color: #fff;
}

.topnav {
  background: $global-secondary-background;
  a {letter-spacing: 0.075em;}
  .logo {
    height: 54px;
    width: auto;
    padding: 10px 10px 10px 0px;
    @media (min-width: $breakpoint-large) {
      height: 70px;
      padding: 10px 20px 10px 0px;
    }
    
  }


  .uk-sticky-fixed {
    background: $global-secondary-background;
  }
}
.uk-navbar-nav>li {
  position:realtive;
}

.uk-navbar-nav>li>a {
  padding: 0 10px;
}

.uk-navbar-nav>li.uk-active>a, .uk-navbar-nav>li>a:hover {
    color: #ffffff;
}
.navbar-icon {
  padding: 5px;
  margin: -4px 8px 0 0;
  &:hover {color: #fff;}
}

.subnav {
  background: #B99B65;
  
  .uk-subnav li a {
    padding: 25px 5px 20px 5px;
    color: rgba(255,255,255,0.8);
    letter-spacing: 0.075em;
  


    @media (min-width: $breakpoint-large) {
      
      padding: 25px 20px 20px 20px;
    }

  }

  .uk-subnav li.uk-active a, li a:hover {color: rgba(255,255,255,1);}
}

.subsubnav {
  background: #f8f8f8;
  .uk-subnav > li {
  
    padding-left: 5px !important;
    @media (min-width: $breakpoint-large) {
      padding-left: 20px !important;
    }
  }
  .uk-subnav li a {
    padding: 20px 5px 15px 5px;
    color: #262626;
    letter-spacing: 0.075em;
    font-size: 12px;
    @media (min-width: $breakpoint-large) {
      
      padding: 20px 10px 15px 10px;
    }
   
  }

  .uk-subnav li.uk-active a, li a:hover {color: #B99B65;}
}




.main-content {
  padding: 40px 0;

  @media (min-width: $breakpoint-medium) {
    padding: 60px 0;
  }

  h1 {
    margin-bottom: 1.4em;
  }

}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 5px solid #B99B65;
  margin: -5px auto 0 auto;
}

.subnav .arrow-up, .subnav .arrow-up.arrow-up-white, .subsubnav .arrow-up{
  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 5px solid #ffffff;
  margin: -5px auto 0 auto;
}

#offcanvas-nav-primary .arrow-up {
  display: none;
}
span.breadcumb {
  display: inline-block;
  text-transform: uppercase;
  color: #999;
  font-size: 0.9em;
  padding-bottom: 5px;
}

.disclaimer {
  text-align: center;
  padding: 80px 10px;

}

.welcome {
  height: 500px;
  background-image: url("../img/DSC_7560-2.jpg");
  background-size: cover;
  background-repeat:  no-repeat;
  background-position: center;

    @media (min-width: $breakpoint-medium) {
     height: 700px;
  }
}

.home-overlay {

  background: rgba(255,255,255,0.88);
  padding: 50px; 
  width: 50%;
  max-width: 580px;

  h1 {
    font-weight: 100;

     @media (max-width: $breakpoint-medium) {
     font-size: 1.3em;
    }
  }

}

.feature {
  background: #fff;

}
.feature-bg {
  background: #F6F6F6;
}
.feature-text, .feature-image {
  padding: 60px 40px;


  @media (min-width: $breakpoint-medium) {
    padding: 100px 60px;
  }
  


}
.feature-text h2 {
 font-size: 2.2em;
 font-weight: 100;
}

.feature-content {
 padding-bottom: 1.4em;

}

.footer {
  background: $global-secondary-background;
  padding: 80px 0px;
  color: #fff;
  font-weight: 100;
  
  .copyright {
    color: #999;
    font-size: 10px;
    padding-top: 1.6em;
    letter-spacing: 0.125em;
  }

  
}
.footer-nav  {
padding: 0em 0em 2.6em 0em;
}
.footer-nav a {
    padding: 0 1em 0.5em 0;
      display: inline-block;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.075em;
      font-size: 12px;
  }
.footer-nav a:hover {
  color: #fff;
}
.footer-icon {
  padding: 5px;
  margin: -4px 8px 1em 0;
  color: $global-link-color;
  &:hover {color: #fff;}

  

  
}

.menu-wrapper {
  padding: 0 20px;
    @media (min-width: $breakpoint-medium) {
      padding: 0 60px;
  }
}
.menu-category {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.2em;
  color: $global-link-color;
  letter-spacing: 0.075em;
  margin-top: 2.8em;
}

.menu-item {
  padding: 1.4em 0 1.1em 0;
  margin-top: 0px !important;
  border-bottom: 1px solid #f4f4f4;
}




.menu-item-title { font-weight: 500; font-size: 1.1em; padding-bottom:3px;color: #262626;}
.menu-item-desc { font-size: 0.9em; font-weight: 100;}
.menu-item-price { color: $global-link-color; font-weight: 400;}
.menu-item-icons {
  display: inline-block;
  margin-left: 0.6em;
  img {margin-top: -3px;
   cursor: pointer;}
}


.uk-tooltip {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif !important;
 
}




.legend {
  border: 1px dashed lighten($global-link-hover-color, 20%);
  padding: 30px 120px 30px 30px;
  display: inline-block;
  font-size: 0.9em;
  margin-top: 40px;
}

.legend-title {
  padding-bottom: 12px; 
  font-weight: 500;
  text-transform: uppercase;
  color: $global-link-color;
}




.variants {
  padding: 15px 0 0 0;

  font-weight: 500;
  
}
.variant {
  display: inline-block;
  margin-right: 2.5em;
  text-transform: uppercase;
    font-size: 0.9em;
}
.variant-price {
  color:#d8a958;
  font-size: 1.3em;
  padding-left: 0.25em;
}

.uk-card-default {
  margin-bottom: 1.8em;
}
.uk-card-title {
  font-size: 2.2em;
}


.contact-form {

  padding: 30px 40px 30px 40px;
  
  @media (min-width: $breakpoint-medium) {
     padding: 60px 80px 60px 80px;
  }
  background: #f6f6f6
}

.contact-page .uk-card-default {
background: #fbfbfb;
color: #262626;
box-shadow: none;
border: 1px solid #f6f6f6;
}

.contact-page  a.uk-button {
  width: 20em;
   @media (max-width: $breakpoint-medium) {
    margin-top: 1.4em;
  }
}

.full-name {display: none;}

.radio-label {padding-bottom: 0.5em; display: inline-block; margin-left: 0.5em;}

.uk-radio {
  background-color: #fff;
}

.contact-page a.uk-button.uk-button-primary { 
  border: 1px solid $global-link-color;
  color: $global-link-color;
  background: #fff;
}

.contact-page a.uk-button.uk-button-primary:hover { 
    border: 1px solid $global-link-color;
  color: #fff;
  background: $global-link-color;
}

.uk-alert-danger {
background: #ffffff;
color: #bd2d2d;
border: 1px solid #bd2d2d;
letter-spacing: normal;
}

.uk-alert-close {
  opacity: 1;
}

.default-content {
  h1, h2, h3{
    text-align: center;
  }

  h2 {
    color: #B99B65;
  }
  text-align: left;
  li {
    margin-bottom: 0.8em;
  }
  @media (min-width: $breakpoint-medium) {
    padding: 0 120px;
  }

  @media (min-width: $breakpoint-large) {
    padding: 0 240px;
  }

  ul {
    text-align: left;
    margin-left: 20px;
    @media (min-width: $breakpoint-medium) {
      margin-left: 20px;
    }
    @media (min-width: $breakpoint-large) {
      margin-left: 40px;
    }
    
  }
}

.opentable-form {
  text-align: center;
  background: #f8f8f8;
}